import axios from "axios";
import { useSnackbar } from "notistack";

import ReCAPTCHA from "react-google-recaptcha";

import { Formik, FormikHelpers } from "formik";
import publicReportSchema from "../schemas/publicReport";

import {
  Grid,
  Paper,
  Typography,
  Stack,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Link,
} from "@mui/material";
import { IReportPublicBase } from "../types/report";
import SOURCES, { LIST as SOURCES_LIST } from "../constants/source";
import TYPES, { LIST as TYPES_LIST } from "../constants/type";
import PRIORITIES from "../constants/priority";
import { LoadingButton } from "@mui/lab";
import { serialize } from "object-to-formdata";

import FileUpload from "../components/FileUpload";

const PublicForm: React.FunctionComponent<{}> = () => {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (
    values: IReportPublicBase & { recaptchaToken: string },
    { setSubmitting, resetForm }: FormikHelpers<IReportPublicBase & { recaptchaToken: string }>
  ) => {
    setSubmitting(true);
    axios
      .post("/public/report", serialize(values))
      .then((res) => {
        enqueueSnackbar("Pietiekums veiksmīgi iesūtīts", {
          variant: "success",
        });
        resetForm();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Paper sx={{ padding: 2, width: "100%" }}>
      <Formik<IReportPublicBase & { recaptchaToken: string }>
        initialValues={{
          source: SOURCES.Facebook,
          otherSource: "",
          link: "",
          type: TYPES.Article,
          priority: PRIORITIES.Queue,
          reporterComment: "",
          owner: "",
          files: [],
          recaptchaToken: "",
        }}
        onSubmit={onSubmit}
        validationSchema={publicReportSchema}
      >
        {({
          values,
          isSubmitting,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          submitCount,
          /* and other goodies */
        }) => (
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="source-label">Informācijas avots</InputLabel>
              <Select
                labelId="source-label"
                id="source"
                value={values.source}
                label="Informācijas avots"
                onChange={(event) => {
                  setFieldValue("source", event.target.value);
                }}
                error={Boolean(errors.source) && submitCount > 0}
              >
                {SOURCES_LIST.map((source) => (
                  <MenuItem key={source.type} value={source.type}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {source.icon}
                      <div style={{ paddingLeft: 10 }}>{source.name}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {values.source === SOURCES.Other ? (
              <TextField
                id="otherSource"
                label="Cits informācijas avots"
                variant="outlined"
                value={values.otherSource}
                onChange={handleChange}
                error={Boolean(errors.otherSource) && submitCount > 0}
                helperText={submitCount > 0 ? errors.otherSource : null}
              />
            ) : null}
            <TextField
              id="link"
              label="Saite uz avotu"
              variant="outlined"
              value={values.link}
              onChange={handleChange}
              error={Boolean(errors.link) && submitCount > 0}
              helperText={submitCount > 0 ? errors.link : null}
            />
            <FormControl fullWidth>
              <InputLabel id="source-label">Informācijas tips</InputLabel>
              <Select
                labelId="source-label"
                id="type"
                value={values.type}
                label="Informācijas tips"
                onChange={(event) => {
                  setFieldValue("type", event.target.value);
                }}
                error={Boolean(errors.type) && submitCount > 0}
              >
                {TYPES_LIST.map((type) => (
                  <MenuItem key={type.type} value={type.type}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {type.icon}
                      <div style={{ paddingLeft: 10 }}>{type.name}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {values.type === TYPES.Other ? (
              <TextField
                id="otherType"
                label="Cits informācijas tips"
                variant="outlined"
                value={values.otherType}
                onChange={handleChange}
                error={Boolean(errors.otherType) && submitCount > 0}
                helperText={submitCount > 0 ? errors.otherType : null}
              />
            ) : null}
            <TextField
              id="owner"
              label="Informācijas ievietotājs"
              variant="outlined"
              value={values.owner}
              onChange={handleChange}
              error={Boolean(errors.owner) && submitCount > 0}
              helperText={submitCount > 0 ? errors.owner : null}
            />
            <FileUpload
              value={values.files}
              setFieldValue={setFieldValue}
              maxFiles={parseInt(process.env.REACT_APP_MAX_PUBLIC_IMAGES as string)}
            />
            {Boolean(errors.files) && submitCount > 0 ? (
              <Typography>{errors.files}</Typography>
            ) : null}
            <TextField
              id="reporterComment"
              label="Komentārs"
              multiline
              maxRows={10}
              minRows={2}
              fullWidth
              value={values.reporterComment}
              onChange={handleChange}
              error={Boolean(errors.reporterComment) && submitCount > 0}
              helperText={submitCount > 0 ? errors.reporterComment : null}
            />
            <Grid container justifyContent="flex-end">
              <Grid item>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                  onChange={(token) => {
                    setFieldValue("recaptchaToken", token);
                  }}
                  onExpired={() => {
                    setFieldValue("recaptchaToken", "");
                  }}
                />
                {Boolean(errors.recaptchaToken) && submitCount > 0 ? (
                  <Typography color="error">{errors.recaptchaToken}</Typography>
                ) : null}
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end">
              <Grid item>
                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  size="large"
                  onClick={() => handleSubmit()}
                >
                  Iesūtīt
                </LoadingButton>
              </Grid>
            </Grid>
            <Typography variant="caption">
              Visa sniegtā informācija tiek apkopota un nodota tiesībsargājošajām iestādēm - vairāk
              info:{" "}
              <Link
                href="https://www.pardrosibu.lv/2022/03/01/aicina-zinot-par-krievijas-agresijas-atbalstitajiem-latvija/"
                target="_blank"
              >
                pardrosibu.lv
              </Link>
            </Typography>
            <Typography variant="caption">
              Privātuma politika:{" "}
              <Link href="https://www.pardrosibu.lv/noteikumi/" target="_blank">
                šeit
              </Link>
            </Typography>
          </Stack>
        )}
      </Formik>
    </Paper>
  );
};

export default PublicForm;
