import { Grid, Paper } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { LIST as SOURCES_LIST } from "../constants/source";

import axios from "axios";
import { IReportsMonth, ISourceStats, IUsersWeekStats } from "../types/stats";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";

import moment from "moment";
import ColorHash from "color-hash";
import LoadingOverlay from "../components/LoadingOverlay";
import { useAppSelector } from "../hooks/redux";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

let weekDates: Date[] = [];
for (let day = 6; day >= 0; day--) {
  weekDates.push(moment().subtract(day, "days").toDate());
}

let monthDates: Date[] = [];
for (let day = 29; day >= 0; day--) {
  monthDates.push(moment().subtract(day, "days").toDate());
}

const Stats: React.FunctionComponent<{}> = () => {
  const settings = useAppSelector((state) => state.settings);

  const [isSourcesLoading, setSourcesLoading] = useState(true);
  const [sourcesData, setSourcesData] = useState<ISourceStats[]>([]);

  const [isUserWeekLoading, setUserWeekLoading] = useState(true);
  const [userWeekData, setUserWeekData] = useState<IUsersWeekStats[]>([]);

  const [isReportsMonthLoading, setReportsMonthLoading] = useState(true);
  const [reportsMonthData, setReportsMonthData] = useState<IReportsMonth[]>([]);

  const loadData = useCallback(() => {
    setSourcesLoading(true);
    setUserWeekLoading(true);
    setReportsMonthLoading(true);

    axios
      .get<ISourceStats[]>("/report/stats/source")
      .then((res) => {
        setSourcesData(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setSourcesLoading(false);
      });

    axios
      .get<IUsersWeekStats[]>("/report/stats/users/week")
      .then((res) => {
        setUserWeekData(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setUserWeekLoading(false);
      });

    axios
      .get<IReportsMonth[]>("/report/stats/reports")
      .then((res) => {
        setReportsMonthData(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setReportsMonthLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData();

    const intervalId = setInterval(() => {
      loadData();
    }, 1000 * 60 * 5);

    return () => clearInterval(intervalId);
  }, [loadData]);

  return (
    <Paper sx={{ padding: 2 }}>
      <Grid container spacing={4} columns={settings.fluid ? 10 : undefined}>
        <Grid item xs={12} md={4} xl={settings.fluid ? 2 : undefined}>
          <LoadingOverlay isLoading={isSourcesLoading}>
            <Doughnut
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "30 dienu informācijas avoti",
                  },
                },
              }}
              data={{
                labels: sourcesData.map((sourceData) => {
                  return sourceData._id;
                }),
                datasets: [
                  {
                    label: "# of Reports by source",
                    data: sourcesData.map((sourceData) => {
                      return sourceData.count;
                    }),
                    backgroundColor: sourcesData.map((sourceData) => {
                      const source = SOURCES_LIST.find((s) => s.type === sourceData._id);

                      if (!source) {
                        return "#FF99FF";
                      }

                      return source.color;
                    }),
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </LoadingOverlay>
        </Grid>
        <Grid item xs={12} md={8} xl={settings.fluid ? 4 : undefined}>
          <LoadingOverlay isLoading={isUserWeekLoading}>
            <Bar
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "7 dienu performance",
                  },
                },
                responsive: true,
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true,
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
              }}
              data={{
                labels: weekDates.map((date) => moment(date).format("DD.MM.")),
                datasets: userWeekData.map((userWeek) => {
                  let data: number[] = [];
                  weekDates.forEach((date) => {
                    let userDate = userWeek.data.find((d) =>
                      moment(d.date, "DD.MM.YYYY.").isSame(date, "day")
                    );

                    if (userDate) {
                      data.push(userDate.count);
                    } else {
                      data.push(0);
                    }
                  });

                  var colorHash = new ColorHash();

                  return {
                    label:
                      userWeek.user && userWeek.user[0]
                        ? userWeek.user[0].username
                        : "Publiski iesniegtie",
                    data,
                    backgroundColor: colorHash.hex(
                      userWeek.user && userWeek.user[0]
                        ? userWeek.user[0]._id
                        : "Publiski iesniegtie"
                    ),
                  };
                }),
              }}
            />
          </LoadingOverlay>
        </Grid>
        <Grid item xs={12} xl={settings.fluid ? 4 : undefined}>
          <LoadingOverlay isLoading={isReportsMonthLoading}>
            <Bar
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: "30 dienu pieteikumi",
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      stepSize: 5,
                    },
                  },
                },
              }}
              data={{
                labels: monthDates.map((date) => moment(date).format("DD.MM.")),
                datasets: [
                  {
                    data: monthDates.map((date) => {
                      let report = reportsMonthData.find((d) =>
                        moment(d._id, "DD.MM.YYYY.").isSame(date, "day")
                      );

                      if (report) {
                        return report.count;
                      } else {
                        return 0;
                      }
                    }),
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                  },
                ],
              }}
            />
          </LoadingOverlay>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Stats;
