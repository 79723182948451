import React from "react";

// constants & types
import MODALS from "../constants/modals";

// utils
import { useAppSelector } from "../hooks/redux";

// modals
import ApproveModal from "../modals/Approve";
import ModerateModal from "../modals/Moderate";
import ViewModal from "../modals/View";

const ModalManager: React.FunctionComponent<{}> = () => {
  const modals = useAppSelector((state) => state.modals);

  return modals.length === 0 ? null : (
    <>
      {modals.map((modal) => {
        switch (modal.type) {
          case MODALS.Approve:
            return <ApproveModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.Moderate:
            return <ModerateModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.View:
            return <ViewModal key={`modal_${modal.id}`} {...modal} />;
          default:
            console.error("Undefined modal component");
            return null;
        }
      })}
    </>
  );
};

export default ModalManager;
