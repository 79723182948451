import { Box, CircularProgress } from "@mui/material";

interface ILoadingOverlayParams {
  isLoading?: boolean;
}

const LoadingOverlay: React.FunctionComponent<ILoadingOverlayParams> = ({
  children,
  isLoading,
}) => {
  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      {children}
      {isLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: -10,
            left: -10,
            right: -10,
            bottom: -10,
            background: "rgba(0,0,0,0.4)",
            cursor: "wait",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 999,
          }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default LoadingOverlay;
