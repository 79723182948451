import * as yup from "yup";
import PRIORITIES from "../constants/priority";
import SOURCES from "../constants/source";
import TYPES from "../constants/type";

const reportSchema = () => {
  return yup.object().shape({
    source: yup.mixed().oneOf(Object.values(SOURCES)).required(),
    link: yup
      .string()
      .url("Nekorekta saite")
      .required("Obligāts lauks")
      .max(2000, "Maksimālais simbolus skaits: 2000"),
    type: yup.mixed().oneOf(Object.values(TYPES)).required(),
    owner: yup.string().max(500, "Maksimālais simbolus skaits: 500"),
    priority: yup.mixed().oneOf(Object.values(PRIORITIES)).required(),
    reporterComment: yup
      .string()
      .required("Obligāts lauks")
      .max(5000, "Maksimālais simbolus skaits: 5000"),
    isDangerous: yup.boolean(),
    dangerousContent: yup.string().nullable().max(5000, "Maksimālais simbolus skaits: 5000"),
    files: yup.array().max(parseInt(process.env.REACT_APP_MAX_PUBLIC_IMAGES as string)),
  });
};

export default reportSchema;
