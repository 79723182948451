import React, { useEffect, useState } from "react";

// utils
import { useAuth0 } from "@auth0/auth0-react";
import { routes } from "./config/routes";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Grid,
  Container,
  IconButton,
  Divider,
  Typography,
  List,
  Toolbar,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  Drawer as MuiDrawer,
  CssBaseline,
  MenuItem,
  Avatar,
  Menu,
  Tooltip,
  CircularProgress,
  useMediaQuery,
  ButtonGroup,
  Stack,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import { Link as RouterLink } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";

import PersonIcon from "@mui/icons-material/Person";

import logo from "./assets/img/logo.png";
import ModalManager from "./managers/Modal";
import { RouteType } from "./types/route";

import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import LaptopWindowsIcon from "@mui/icons-material/LaptopWindows";
import LoginIcon from "@mui/icons-material/Login";

import { useAppDispatch, useAppSelector } from "./hooks/redux";
import { setFluid } from "./features/settings";

import ukraineFlag from "./assets/img/ukraine_flag.jpg";
import logoZevs from "./assets/img/logo_zevs.png";

import PublicForm from "./views/PublicForm";

const drawerWidth: number = 240;

const mdTheme = createTheme();

const Layout: React.FunctionComponent<{}> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      {isLoading ? (
        <Box
          sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          {isAuthenticated ? (
            <RenderApplication>{children}</RenderApplication>
          ) : (
            <RenderAuthentication />
          )}
        </>
      )}
    </ThemeProvider>
  );
};

const RenderAuthentication = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Container
      component="main"
      maxWidth="md"
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          width: "100%",
          height: "100vh",
          position: "fixed",
          backgroundImage: `url(${ukraineFlag})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          left: 0,
          top: 0,
          opacity: 0.6,
          zIndex: 0,
        }}
      ></div>

      <Tooltip title="Autorizēties">
        <IconButton
          size="large"
          onClick={() => loginWithRedirect()}
          sx={{ position: "absolute", zIndex: 100, color: "white", right: 20, top: 10 }}
        >
          <LoginIcon />
        </IconButton>
      </Tooltip>
      <Stack flex={1} sx={{ zIndex: 10, pt: 4 }} spacing={2}>
        <Typography variant="h2" textAlign="center" sx={{ mb: 6, fontWeight: 700, color: "white" }}>
          Ziņo par agresiju
        </Typography>
        <Typography style={{ color: "white" }}>
          Tiesībsargājošās iestādes aicina iedzīvotājus ziņot par Krievijas agresijas atbalstītājiem
          un nacionālā naida izraisītājiem Latvijas kibertelpā. Šī forma ir radīta, lai atvieglotu
          ziņošanas procesu un drošības iestādēm nodotu strukturētu informāciju par agresoriem
          kibertelpā un padarītu mūsu valsti un sabiedrību drošāku. Šī nav "stučīšana", jo agresori
          katru dienu pavada domājot par to, kā Latviju padarīt par Krievijas guberņu un kara
          gadījumā pavērstu ieročus pret tevi!
        </Typography>
        <Stack alignItems="center" spacing={4}>
          <PublicForm />
        </Stack>
      </Stack>
      <Copyright sx={{ mt: 4, mb: 4, zIndex: 5 }} />
    </Container>
  );
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const RenderApplication: React.FunctionComponent<{}> = ({ children }) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const { user } = useAuth0();
  const dispatch = useAppDispatch();

  const settings = useAppSelector((state) => state.settings);

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (mdDown) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [mdDown]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open && !mdDown}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <ProfileDropdown />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          variant={mdDown ? "temporary" : "permanent"}
          open={open}
          ModalProps={{
            keepMounted: true,
          }}
          onClose={toggleDrawer}
        >
          <Toolbar
            sx={{
              px: [1],
              overflow: "hidden",
              maxHeight: 64,
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              {open ? (
                <Grid item>
                  <Link underline="none" href="/">
                    <img src={logo} alt="logo" style={{ width: 120 }} />
                  </Link>
                </Grid>
              ) : null}
              <Grid item>
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
          {routes.map((route, index) => {
            if (route.type === RouteType.Menu) {
              if (route.allowedRoles) {
                if (!user) {
                  return null;
                }

                if (
                  !(user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/roles`] as string[]).some(
                    (role) => route.allowedRoles?.includes(role)
                  )
                ) {
                  return null;
                }
              }
              return (
                <List key={index}>
                  <Link
                    component={RouterLink}
                    to={route.path}
                    underline="none"
                    color="inherit"
                    onClick={() => {
                      if (mdDown) {
                        toggleDrawer();
                      }
                    }}
                  >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>{route.icon}</ListItemIcon>
                        <ListItemText primary={route.name} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </List>
              );
            } else {
              return null;
            }
          })}
          <Grid container flexDirection="column" flex={1} justifyContent="flex-end" sx={{ p: 1 }}>
            <Grid item>
              <ButtonGroup
                orientation={open ? "horizontal" : "vertical"}
                variant="outlined"
                aria-label="outlined button group"
              >
                <IconButton
                  disabled={settings.fluid}
                  onClick={() => {
                    dispatch(setFluid(true));
                  }}
                >
                  <DesktopWindowsIcon />
                </IconButton>
                <IconButton
                  disabled={!settings.fluid}
                  onClick={() => {
                    dispatch(setFluid(false));
                  }}
                >
                  <LaptopWindowsIcon />
                </IconButton>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth={settings.fluid ? false : "lg"} sx={{ mt: 4, mb: 4 }}>
            {children}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      <ModalManager />
    </ThemeProvider>
  );
};

function ProfileDropdown() {
  const { logout, user } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <PersonIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Link component={RouterLink} to="/profile" underline="none" color="inherit">
          <MenuItem>
            <Avatar alt={user?.name} src={user?.picture} /> Profils
          </MenuItem>
        </Link>
        <Divider />
        <MenuItem
          onClick={() => {
            logout();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Iziet
        </MenuItem>
      </Menu>
    </>
  );
}

function Copyright(props: any) {
  return (
    <Grid container justifyContent="center" alignItems="center" {...props} spacing={1}>
      <Grid item>
        <Typography color="text.secondary">Security solutions by</Typography>
      </Grid>
      <Grid item>
        <Link color="inherit" href="https://zevs.lv" target="_blank">
          <img
            src={logoZevs}
            style={{ height: 16, marginTop: 7 }}
            alt="ZEVS Security solutions logo"
          />
        </Link>
      </Grid>
    </Grid>
  );
}

export default Layout;
