import axios from "axios";
import { useSnackbar } from "notistack";

import { Formik, FormikHelpers } from "formik";
import reportSchema from "../schemas/report";

import {
  Grid,
  Paper,
  Typography,
  Stack,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  FormGroup,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { IReportBase } from "../types/report";
import SOURCES, { LIST as SOURCES_LIST } from "../constants/source";
import TYPES, { LIST as TYPES_LIST } from "../constants/type";
import PRIORITIES, { LIST as PRIORITIES_LIST } from "../constants/priority";
import { LoadingButton } from "@mui/lab";
import { serialize } from "object-to-formdata";

import FileUpload from "../components/FileUpload";

const Report: React.FunctionComponent<{}> = () => {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (
    values: IReportBase,
    { setSubmitting, resetForm }: FormikHelpers<IReportBase>
  ) => {
    setSubmitting(true);
    axios
      .post("/report", serialize(values))
      .then((res) => {
        enqueueSnackbar("Pietiekums veiksmīgi iesūtīts", {
          variant: "success",
        });
        resetForm();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Formik<IReportBase>
        initialValues={{
          source: SOURCES.Facebook,
          otherSource: "",
          link: "",
          type: TYPES.Article,
          priority: PRIORITIES.Queue,
          reporterComment: "",
          owner: "",
          isDangerous: false,
          dangerousContent: "",
          files: [],
        }}
        onSubmit={onSubmit}
        validationSchema={reportSchema}
      >
        {({
          values,
          isSubmitting,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          submitCount,
          /* and other goodies */
        }) => (
          <Stack spacing={2}>
            <Typography variant="h4" component="h1">
              Jauns pieteikums
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="source-label">Informācijas avots</InputLabel>
              <Select
                labelId="source-label"
                id="source"
                value={values.source}
                label="Informācijas avots"
                onChange={(event) => {
                  setFieldValue("source", event.target.value);
                }}
                error={Boolean(errors.source) && submitCount > 0}
              >
                {SOURCES_LIST.map((source) => (
                  <MenuItem key={source.type} value={source.type}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {source.icon}
                      <div style={{ paddingLeft: 10 }}>{source.name}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {values.source === SOURCES.Other ? (
              <TextField
                id="otherSource"
                label="Cits informācijas avots"
                variant="outlined"
                value={values.otherSource}
                onChange={handleChange}
                error={Boolean(errors.otherSource) && submitCount > 0}
                helperText={submitCount > 0 ? errors.otherSource : null}
              />
            ) : null}
            <TextField
              id="link"
              label="Saite uz avotu"
              variant="outlined"
              value={values.link}
              onChange={handleChange}
              error={Boolean(errors.link) && submitCount > 0}
              helperText={submitCount > 0 ? errors.link : null}
            />
            <FormControl fullWidth>
              <InputLabel id="source-label">Informācijas tips</InputLabel>
              <Select
                labelId="source-label"
                id="type"
                value={values.type}
                label="Informācijas tips"
                onChange={(event) => {
                  setFieldValue("type", event.target.value);
                }}
                error={Boolean(errors.type) && submitCount > 0}
              >
                {TYPES_LIST.map((type) => (
                  <MenuItem key={type.type} value={type.type}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {type.icon}
                      <div style={{ paddingLeft: 10 }}>{type.name}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {values.type === TYPES.Other ? (
              <TextField
                id="otherType"
                label="Cits informācijas tips"
                variant="outlined"
                value={values.otherType}
                onChange={handleChange}
                error={Boolean(errors.otherType) && submitCount > 0}
                helperText={submitCount > 0 ? errors.otherType : null}
              />
            ) : null}
            <TextField
              id="owner"
              label="Informācijas ievietotājs"
              variant="outlined"
              value={values.owner}
              onChange={handleChange}
              error={Boolean(errors.owner) && submitCount > 0}
              helperText={submitCount > 0 ? errors.owner : null}
            />
            <FormControl fullWidth>
              <InputLabel id="source-label">Satura revīzijas prioritāte</InputLabel>
              <Select
                labelId="source-label"
                id="priority"
                value={values.priority}
                label="Satura revīzijas prioritāte"
                onChange={(event) => {
                  setFieldValue("priority", event.target.value);
                }}
                error={Boolean(errors.priority) && submitCount > 0}
              >
                {PRIORITIES_LIST.map((type) => (
                  <MenuItem key={type.type} value={type.type}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {type.icon}
                      <div style={{ paddingLeft: 10 }}>{type.name}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FileUpload
              value={values.files}
              setFieldValue={setFieldValue}
              maxFiles={parseInt(process.env.REACT_APP_MAX_IMAGES as string)}
            />
            <TextField
              id="reporterComment"
              label="Komentārs"
              multiline
              maxRows={10}
              minRows={2}
              fullWidth
              value={values.reporterComment}
              onChange={handleChange}
              error={Boolean(errors.reporterComment) && submitCount > 0}
              helperText={submitCount > 0 ? errors.reporterComment : null}
            />
            <FormGroup>
              <FormControlLabel
                checked={values.isDangerous}
                control={<Switch defaultChecked />}
                label="Bīstams saturs"
                onChange={(e, checked) => setFieldValue("isDangerous", checked)}
              />
            </FormGroup>
            {values.isDangerous ? (
              <TextField
                id="dangerousContent"
                label="Bīstams saturs"
                multiline
                maxRows={10}
                minRows={2}
                fullWidth
                value={values.dangerousContent}
                onChange={handleChange}
                error={Boolean(errors.dangerousContent) && submitCount > 0}
                helperText={submitCount > 0 ? errors.dangerousContent : null}
              />
            ) : null}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  size="large"
                  onClick={() => handleSubmit()}
                >
                  Iesūtīt
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Formik>
    </Paper>
  );
};

export default Report;
