import { useCallback, useEffect, useState } from "react";

import SOURCES, { LIST as SOURCES_LIST } from "../constants/source";
import TYPES, { LIST as TYPES_LIST } from "../constants/type";
import STATUSES, { LIST as STATUSES_LIST } from "../constants/status";
import PRIORITIES, { LIST as PRIORITIES_LIST } from "../constants/priority";

import axios from "axios";

import {
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Button,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

import { DatePicker, Pagination } from "@mui/lab";
import { IPagination, IRequest } from "../types/common";
import { IReportResponse, IReportFilters } from "../types/report";
import moment from "moment";
import { IUser } from "../types/user";
import { useAppDispatch } from "../hooks/redux";
import { openModal } from "../features/modals";
import MODALS from "../constants/modals";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: `${moment().format("DD.MM.YYYY.")}_reports`,
          utf8WithBom: true,
          allColumns: true,
          includeHeaders: false,
        }}
        touchRippleRef={undefined}
        printOptions={{
          hideFooter: true,
          hideToolbar: true,
        }}
      />
    </GridToolbarContainer>
  );
}

const AllReports: React.FunctionComponent<{}> = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(true);
  const [request, setRequest] = useState<IRequest<IReportFilters>>({
    page: 1,
    limit:
      localStorage.getItem("pageSize") &&
      Number.isInteger(parseInt(localStorage.getItem("pageSize") as string))
        ? parseInt(localStorage.getItem("pageSize") as string)
        : 25,
    sort: [
      {
        field: "created",
        sort: "desc",
      },
    ],
  });
  const [data, setData] = useState<IPagination<IReportResponse>>();

  const loadData = useCallback(() => {
    setLoading(true);
    axios
      .get<IPagination<IReportResponse>>("/report/all", { params: request })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [request]);

  const onSortModelChange = (model: GridSortModel) => {
    setRequest((e) => ({
      ...e,
      page: 1,
      sort: model,
    }));
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns: GridColDef[] = [
    {
      field: "seq",
      headerName: "id",
      width: 30,
      sortable: false,
    },

    {
      field: "status",
      headerName: "Statuss",
      width: 82,
      renderCell: (params) => {
        const status = STATUSES_LIST.find((s) => s.type === (params.value as STATUSES));

        if (status) {
          return (
            <Tooltip title={status.name}>
              <span className="table-cell-trucate">{status.icon}</span>
            </Tooltip>
          );
        }
        return "";
      },
      align: "center",
    },

    {
      field: "priority",
      headerName: "Prioritāte",
      width: 91,
      renderCell: (params) => {
        const priority = PRIORITIES_LIST.find((s) => s.type === (params.value as PRIORITIES));

        if (priority) {
          return (
            <Tooltip title={priority.name}>
              <span className="table-cell-trucate">{priority.icon}</span>
            </Tooltip>
          );
        }
        return "";
      },
      align: "center",
    },
    {
      field: "isDangerous",
      headerName: "Bīstams",
      width: 87,
      renderCell: (params) => {
        return params.value ? "Jā" : "Nē";
      },
      align: "center",
    },
    {
      field: "dangerousContent",
    },
    {
      field: "created",
      headerName: "Izveidots",
      width: 150,
      valueFormatter: (params) => {
        return moment
          .utc(params.value as Date)
          .local()
          .format("L LT");
      },
    },
    {
      field: "user",
      headerName: "Pieteicējs",
      width: 130,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return (params.value as IUser).username;
      },
      sortable: false,
    },
    {
      field: "source",
      headerName: "Avots",
      width: 80,
      renderCell: (params) => {
        const source = SOURCES_LIST.find((s) => s.type === (params.value as SOURCES));

        if (source) {
          return (
            <Tooltip title={source.name}>
              <span className="table-cell-trucate">{source.icon}</span>
            </Tooltip>
          );
        }
        return "";
      },
      align: "center",
    },
    {
      field: "otherSource",
    },
    {
      field: "type",
      headerName: "Tips",
      width: 80,
      renderCell: (params) => {
        const type = TYPES_LIST.find((t) => t.type === (params.value as TYPES));

        if (type) {
          return (
            <Tooltip title={type.name}>
              <span className="table-cell-trucate">{type.icon}</span>
            </Tooltip>
          );
        }
        return "";
      },
      align: "center",
    },
    {
      field: "otherType",
    },
    { field: "reporterComment", headerName: "Komentārs", minWidth: 200, flex: 1, sortable: false },
    {
      field: "realized",
      headerName: "Realizējās",
      width: 95,
      renderCell: (params) => {
        return params.value ? "Jā" : "Nē";
      },
      align: "center",
    },
    {
      field: "link",
      headerName: "Saite",
      width: 70,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <span className="table-cell-trucate">
              <Link href={params.value} target="_blank">
                <OpenInNewIcon />
              </Link>
            </span>
          </Tooltip>
        );
      },
      align: "center",
    },
  ];

  return (
    <Stack spacing={2}>
      <Paper sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="source-label">Statuss</InputLabel>
              <Select
                labelId="source-label"
                value={request.filters ? request.filters.status : ""}
                label="Statuss"
                onChange={(event) => {
                  setRequest((r) => ({
                    ...r,
                    page: 1,
                    filters: { ...r.filters, status: event.target.value as STATUSES },
                  }));
                }}
              >
                <MenuItem>Jebkurš</MenuItem>
                {STATUSES_LIST.map((status) => (
                  <MenuItem key={status.type} value={status.type}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {status.icon}
                      <div style={{ paddingLeft: 10 }}>{status.name}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="source-label">Prioritāte</InputLabel>
              <Select
                labelId="source-label"
                value={request.filters ? request.filters.priority : ""}
                label="Prioritāte"
                onChange={(event) => {
                  setRequest((r) => ({
                    ...r,
                    page: 1,
                    filters: { ...r.filters, priority: event.target.value as PRIORITIES },
                  }));
                }}
              >
                <MenuItem>Jebkurš</MenuItem>
                {PRIORITIES_LIST.map((priority) => (
                  <MenuItem key={priority.type} value={priority.type}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {priority.icon}
                      <div style={{ paddingLeft: 10 }}>{priority.name}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="source-label">Informācijas avots</InputLabel>
              <Select
                labelId="source-label"
                value={request.filters ? request.filters.source : ""}
                label="Informācijas avots"
                onChange={(event) => {
                  setRequest((r) => ({
                    ...r,
                    page: 1,
                    filters: { ...r.filters, source: event.target.value as SOURCES },
                  }));
                }}
              >
                <MenuItem>Jebkurš</MenuItem>
                {SOURCES_LIST.map((source) => (
                  <MenuItem key={source.type} value={source.type}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {source.icon}
                      <div style={{ paddingLeft: 10 }}>{source.name}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="source-label">Informācijas tips</InputLabel>
              <Select
                labelId="source-label"
                value={request.filters ? request.filters.type : ""}
                label="Informācijas tips"
                onChange={(event) => {
                  setRequest((r) => ({
                    ...r,
                    page: 1,
                    filters: { ...r.filters, type: event.target.value as TYPES },
                  }));
                }}
              >
                <MenuItem>Jebkurš</MenuItem>
                {TYPES_LIST.map((type) => (
                  <MenuItem key={type.type} value={type.type}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {type.icon}
                      <div style={{ paddingLeft: 10 }}>{type.name}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DatePicker
              label="Izveidots"
              value={request.filters?.created ? request.filters?.created : null}
              onChange={(date) => {
                setRequest((r) => ({
                  ...r,
                  page: 1,
                  filters: { ...r.filters, created: date ? date : undefined },
                }));
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
              // mask="__.__.____."
            />
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={() => {
                  setRequest((r) => ({ ...r, filters: undefined }));
                }}
              >
                Notīrīt filtrus
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ padding: 2 }}>
        <Stack spacing={2}>
          <DataGrid
            columnVisibilityModel={{
              otherSource: false,
              dangerousContent: false,
              otherType: false,
            }}
            getRowId={(row) => row._id}
            onRowClick={(params) => {
              dispatch(
                openModal({
                  type: MODALS.Moderate,
                  report: params.row as IReportResponse,
                  callback: () => loadData(),
                })
              );
            }}
            rows={data && data.docs ? data.docs : []}
            columns={columns}
            hideFooterPagination
            loading={isLoading}
            autoHeight
            onSortModelChange={onSortModelChange}
            disableSelectionOnClick
            // disableColumnMenu
            sortingMode="server"
            sortModel={request.sort}
            hideFooter
            components={{
              Toolbar: CustomToolbar,
            }}
          />

          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <FormControl fullWidth>
                <Select
                  labelId="limit-label"
                  value={request.limit}
                  onChange={(event) => {
                    localStorage.setItem("pageSize", event.target.value.toString());
                    setRequest((e) => ({
                      ...e,
                      page: 1,
                      limit: parseInt(event.target.value.toString()),
                    }));
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Pagination
                color="primary"
                variant="outlined"
                count={data?.totalPages}
                page={request.page}
                onChange={(e, page) => {
                  setRequest((e) => ({ ...e, page }));
                }}
              />
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default AllReports;
