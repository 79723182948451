import { useAuth0 } from "@auth0/auth0-react";

import { Grid, Paper, Avatar, Typography, Badge } from "@mui/material";

const Profile: React.FunctionComponent<{}> = () => {
  const { user } = useAuth0();

  return (
    <Paper sx={{ padding: 2 }}>
      <Grid container flexDirection="column" spacing={2}>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <Badge
              variant="dot"
              badgeContent=" "
              color={user?.email_verified ? "success" : "warning"}
            >
              <Avatar alt={user?.name} src={user?.picture} />
            </Badge>
          </Grid>
          <Grid item container flexDirection="column" xs>
            <Grid item>
              <Typography>{user?.name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{user?.email}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Profile;
