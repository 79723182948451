import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNewspaper,
  faVideo,
  faImage,
  faComment,
  faUser,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

export enum TYPES {
  Article = "article",
  Video = "video",
  Image = "image",
  Comment = "comment",
  Profile = "profile",
  Other = "other",
}

export interface ITypeListItem {
  type: TYPES;
  name: string;
  icon: JSX.Element;
}

export const ARTICLE_LIST_ITEM: ITypeListItem = {
  type: TYPES.Article,
  name: "Ieraksts (teksts)",
  icon: <FontAwesomeIcon icon={faNewspaper} />,
};

export const VIDEO_LIST_ITEM: ITypeListItem = {
  type: TYPES.Video,
  name: "Video",
  icon: <FontAwesomeIcon icon={faVideo} />,
};

export const IMAGE_LIST_ITEM: ITypeListItem = {
  type: TYPES.Image,
  name: "Attēls",
  icon: <FontAwesomeIcon icon={faImage} />,
};

export const COMMENT_LIST_ITEM: ITypeListItem = {
  type: TYPES.Comment,
  name: "Komentārs",
  icon: <FontAwesomeIcon icon={faComment} />,
};

export const PROFILE_LIST_ITEM: ITypeListItem = {
  type: TYPES.Profile,
  name: "Profils",
  icon: <FontAwesomeIcon icon={faUser} />,
};

export const OTHER_LIST_ITEM: ITypeListItem = {
  type: TYPES.Other,
  name: "cits",
  icon: <FontAwesomeIcon icon={faQuestion} />,
};

export const LIST: ITypeListItem[] = [
  ARTICLE_LIST_ITEM,
  VIDEO_LIST_ITEM,
  IMAGE_LIST_ITEM,
  COMMENT_LIST_ITEM,
  PROFILE_LIST_ITEM,
  OTHER_LIST_ITEM,
];

export default TYPES;
