export enum RouteType {
  Menu = "Menu",
  Basic = "Basic",
}

interface IRouteBase {
  path: string;
  element: any;
  allowedRoles?: string[];
}

interface IMenuRoute extends IRouteBase {
  type: RouteType.Menu;
  name: string;
  icon: JSX.Element;
}

interface IBasicRoute extends IRouteBase {
  type: RouteType.Basic;
}

export type TRoute = IMenuRoute | IBasicRoute;
