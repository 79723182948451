import { Link } from "@mui/material";
import axios from "axios";
import { IReportFile } from "../types/report";

interface IDownloadFile {
  file: IReportFile;
}

export default function DownloadFile({ file }: IDownloadFile) {
  const handleDownload = () => {
    axios
      .get(file.path, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  return (
    <Link onClick={handleDownload} style={{ cursor: "pointer" }}>
      {file.filename}
    </Link>
  );
}
