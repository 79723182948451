import { Box, IconButton, List, ListItem, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";

import DeleteIcon from "@mui/icons-material/Delete";

interface IFileUploadProps {
  value?: any[];
  setFieldValue: (field: string, value: any) => void;
  maxFiles: number;
}

export default function FileUpload({ value, setFieldValue, maxFiles }: IFileUploadProps) {
  const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    maxFiles: maxFiles,
    accept: process.env.REACT_APP_ACCEPTED_FILES,
    maxSize: parseInt(process.env.REACT_APP_MAX_FILE_SIZE as string),
    onDrop: (acceptedFiles) => {
      setFieldValue("files", [...(value ? value : []), ...acceptedFiles]);
    },
  });

  document.addEventListener("paste", function (evt) {
    // Get the data of clipboard
    // @ts-ignore
    const clipboardItems = evt.clipboardData.items;
    const items = [].slice.call(clipboardItems).filter(function (item) {
      // Filter the image items only
      // @ts-ignore
      return item.type.indexOf("image") !== -1;
    });
    if (items.length === 0) {
      return;
    }

    const item = items[0];
    // Get the blob of image

    // @ts-ignore
    const blob = item.getAsFile();
    setFieldValue("files", [...(value ? value : []), blob]);
  });

  return (
    <Box>
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{
          padding: 14,
          borderWidth: 3,
          borderStyle: "dashed",
          borderColor: isDragAccept ? "green" : isDragReject ? "red" : "gray",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <input {...getInputProps()} />
        <Typography color={isDragAccept ? "green" : isDragReject ? "red" : "black"}>
          Ievelc failu/-s, klikšķini, lai izvēlētos vai ctrl+v, lai ielīmētu no starpliktuves
        </Typography>
        <Typography variant="caption">(max. {maxFiles} faili)</Typography>
      </div>
      <List>
        {value?.map((file, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  setFieldValue(
                    "files",
                    value.filter((f) => f !== file)
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            {(file as File).name}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
