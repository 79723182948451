import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

export enum STATUSES {
  New = "new",
  Reviewed = "reviewed",
}

export interface IStatusListItem {
  type: STATUSES;
  name: string;
  icon: JSX.Element;
}

export const NEW_LIST_ITEM: IStatusListItem = {
  type: STATUSES.New,
  name: "Ieraksts veikts un nav veikta revīzija",
  icon: <FontAwesomeIcon icon={faCircle} />,
};

export const REVIEWED_LIST_ITEM: IStatusListItem = {
  type: STATUSES.Reviewed,
  name: "Ierakstu apstrādājis moderators",
  icon: <FontAwesomeIcon icon={faCircleCheck} />,
};

export const LIST: IStatusListItem[] = [NEW_LIST_ITEM, REVIEWED_LIST_ITEM];

export default STATUSES;
