import "./App.css";

import { Routes, Route } from "react-router-dom";

import { routes } from "./config/routes";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import Layout from "./Layout";

function App() {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();

  // Populate authorization header with bearer token
  axios.interceptors.request.use(
    async (config) => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          },
        };
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <Layout>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.element />} />
        ))}
      </Routes>
    </Layout>
  );
}

export default App;
