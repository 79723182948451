import { IViewModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  List,
  ListItem,
} from "@mui/material";
import moment from "moment";
import { LIST as STATUSES_LIST } from "../constants/status";
import SOURCES, { LIST as SOURCES_LIST } from "../constants/source";
import TYPES, { LIST as TYPES_LIST } from "../constants/type";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadFile from "../components/DownloadFile";

const ViewModal: React.FunctionComponent<IViewModalProps> = ({ id, report }) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  const status = STATUSES_LIST.find((s) => s.type === report.status);
  const source = SOURCES_LIST.find((s) => s.type === report.source);
  const type = TYPES_LIST.find((t) => t.type === report.type);

  return (
    <Dialog fullWidth={true} maxWidth="md" open={true} onClose={handleClose}>
      <DialogTitle>Pieteikums</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Grid container spacing={2}>
            <Grid item>
              {status?.icon} {status?.name}
            </Grid>
            <Grid item>
              {source?.icon} {source?.type === SOURCES.Other ? report.otherSource : source?.name}
            </Grid>
            <Grid item>
              {type?.icon} {type?.type === TYPES.Other ? report.otherType : type?.name}
            </Grid>
          </Grid>
          <Typography variant="h6">
            Pieteicējs: {report.user?.username} (
            {moment.utc(report.created).local().format("LL LT")})
          </Typography>
          {report.files ? (
            <Stack>
              <Typography variant="h6">Faili:</Typography>
              <List>
                {report.files.map((file) => {
                  return (
                    <ListItem key={file.filename}>
                      <DownloadFile file={file} />
                    </ListItem>
                  );
                  // switch (true) {
                  //   case /video/.test(file.mimetype):
                  //     return <VideoPlayer path={file.path} filename={file.filename} />;
                  //   default:
                  //     return <Image path={file.path} filename={file.filename} />;
                  //     break;
                  // }
                })}
              </List>
            </Stack>
          ) : null}
          <Stack>
            <Typography variant="h6">Saite:</Typography>
            <Typography>
              <Link href={report.link}>{report.link}</Link>
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h6">Pieteicēja komentārs:</Typography>
            <Typography>{report.reporterComment}</Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ViewModal;
