import { TRoute, RouteType } from "../types/route";

import ProfileView from "../views/Profile";

import Reports from "../views/Reports";
import AllReports from "../views/AllReports";
import Report from "../views/Report";
import Stats from "../views/Stats";

import TaskIcon from "@mui/icons-material/Task";
import AddIcon from "@mui/icons-material/Add";
import BarChartIcon from "@mui/icons-material/BarChart";

export const routes: TRoute[] = [
  {
    type: RouteType.Basic,
    path: "profile",
    element: ProfileView,
  },
  {
    type: RouteType.Menu,
    name: "Jauns pieteikums",
    path: "/",
    element: Report,
    icon: <AddIcon />,
  },
  {
    type: RouteType.Menu,
    name: "Mani pieteikumi",
    path: "reports",
    element: Reports,
    icon: <TaskIcon />,
  },
  {
    type: RouteType.Menu,
    name: "Visi pieteikumi",
    path: "reports/all",
    element: AllReports,
    icon: <TaskIcon />,
    allowedRoles: ["Moderator"],
  },
  {
    type: RouteType.Menu,
    name: "Statistika",
    path: "stats",
    element: Stats,
    icon: <BarChartIcon />,
    allowedRoles: ["Moderator"],
  },
];
