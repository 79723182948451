import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import {
  faTiktok,
  faFacebook,
  faYoutube,
  faInstagram,
  faTelegram,
  faVk,
  faOdnoklassniki,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

export enum SOURCES {
  TikTok = "tiktok",
  Twitter = "twitter",
  Facebook = "facebook",
  Youtube = "youtube",
  Instagram = "instagram",
  Telegram = "telegram",
  Vk = "vk",
  Odnoklassniki = "Odnoklassniki",
  Other = "other",
}

export interface ISourceListItem {
  type: SOURCES;
  name: string;
  icon: JSX.Element;
  color: string;
}

export const TIKTOK_LIST_ITEM: ISourceListItem = {
  type: SOURCES.TikTok,
  name: "TikTok",
  icon: <FontAwesomeIcon icon={faTiktok} />,
  color: "#ff0050",
};

export const TWITTER_LIST_ITEM: ISourceListItem = {
  type: SOURCES.Twitter,
  name: "Twitter",
  icon: <FontAwesomeIcon icon={faTwitter} />,
  color: "#00acee",
};

export const FACEBOOK_LIST_ITEM: ISourceListItem = {
  type: SOURCES.Facebook,
  name: "Facebook",
  icon: <FontAwesomeIcon icon={faFacebook} />,
  color: "#4267B2",
};

export const YOUTUBE_LIST_ITEM: ISourceListItem = {
  type: SOURCES.Youtube,
  name: "YouTube",
  icon: <FontAwesomeIcon icon={faYoutube} />,
  color: "#FF0000",
};

export const INSTAGRAM_LIST_ITEM: ISourceListItem = {
  type: SOURCES.Instagram,
  name: "Instagram",
  icon: <FontAwesomeIcon icon={faInstagram} />,
  color: "#405de6",
};

export const TELEGRAM_LIST_ITEM: ISourceListItem = {
  type: SOURCES.Telegram,
  name: "Telegram",
  icon: <FontAwesomeIcon icon={faTelegram} />,
  color: "#2AABEE ",
};

export const VK_LIST_ITEM: ISourceListItem = {
  type: SOURCES.Vk,
  name: "VK",
  icon: <FontAwesomeIcon icon={faVk} />,
  color: "#4C75A3",
};

export const ODNOKLASSNIKI_LIST_ITEM: ISourceListItem = {
  type: SOURCES.Odnoklassniki,
  name: "Odnoklassniki",
  icon: <FontAwesomeIcon icon={faOdnoklassniki} />,
  color: "#ed812b",
};

export const OTHER_LIST_ITEM: ISourceListItem = {
  type: SOURCES.Other,
  name: "Cits",
  icon: <FontAwesomeIcon icon={faQuestion} />,
  color: "#808080",
};

export const LIST: ISourceListItem[] = [
  FACEBOOK_LIST_ITEM,
  TWITTER_LIST_ITEM,
  TIKTOK_LIST_ITEM,
  YOUTUBE_LIST_ITEM,
  INSTAGRAM_LIST_ITEM,
  TELEGRAM_LIST_ITEM,
  VK_LIST_ITEM,
  ODNOKLASSNIKI_LIST_ITEM,
  OTHER_LIST_ITEM,
];

export default SOURCES;
