import { IModerateModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById, openModal } from "../features/modals";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Grid,
  Stack,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  List,
  ListItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { useState } from "react";
import { LIST as STATUSES_LIST } from "../constants/status";
import SOURCES, { LIST as SOURCES_LIST } from "../constants/source";
import TYPES, { LIST as TYPES_LIST } from "../constants/type";
import { Formik, FormikHelpers } from "formik";
import { IReportModerator } from "../types/report";
import { useSnackbar } from "notistack";
import axios from "axios";

import VideoPlayer from "../components/VieoPlayer";
import Image from "../components/Image";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadFile from "../components/DownloadFile";
import MODALS from "../constants/modals";

const ModerateModal: React.FunctionComponent<IModerateModalProps> = ({ id, report, callback }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isDeleting, setDeleting] = useState(false);

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  const status = STATUSES_LIST.find((s) => s.type === report.status);
  const source = SOURCES_LIST.find((s) => s.type === report.source);
  const type = TYPES_LIST.find((t) => t.type === report.type);

  const onSubmit = (
    values: IReportModerator,
    { setSubmitting, resetForm }: FormikHelpers<IReportModerator>
  ) => {
    setSubmitting(true);
    axios
      .post(`/report/approve/${report._id}`, values)
      .then((res) => {
        enqueueSnackbar("Pieteikums veiksmīgi apstiprināts!", {
          variant: "success",
        });
        callback();
        handleClose();
      })
      .catch((err) => {
        console.log("err:", err);

        enqueueSnackbar(err, {
          variant: "error",
        });

        setSubmitting(false);
      });
  };

  const onDelete = () => {
    dispatch(
      openModal({
        type: MODALS.Approve,
        title: "Dzēst ierakstu?",
        onApprove: () => {
          setDeleting(true);
          axios
            .delete(`/report/${report._id}`)
            .then((res) => {
              enqueueSnackbar("Pieteikums veiksmīgi dzēsts!", {
                variant: "success",
              });
              callback();
              handleClose();
            })
            .catch((err) => {
              console.log("err:", err);

              enqueueSnackbar(err, {
                variant: "error",
              });

              setDeleting(false);
            });
        },
      })
    );
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" open={true} onClose={handleClose}>
      <Formik<IReportModerator>
        initialValues={{
          ...report,
          moderatorComment: report.moderatorComment ? report.moderatorComment : "",
        }}
        onSubmit={onSubmit}
        // validationSchema={reportSchema}
      >
        {({
          values,
          isSubmitting,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          submitCount,
          /* and other goodies */
        }) => (
          <>
            <DialogTitle>Pieteikums</DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                <Grid container spacing={2}>
                  <Grid item>
                    {status?.icon} {status?.name}
                  </Grid>
                  <Grid item>
                    {source?.icon}{" "}
                    {source?.type === SOURCES.Other ? report.otherSource : source?.name}
                  </Grid>
                  <Grid item>
                    {type?.icon} {type?.type === TYPES.Other ? report.otherType : type?.name}
                  </Grid>
                </Grid>
                <Typography variant="h6">
                  Pieteicējs: {report.user?.username} (
                  {moment.utc(report.created).local().format("LL LT")} - {report.ip})
                </Typography>
                {report.files ? (
                  <Stack>
                    <Typography variant="h6">Faili:</Typography>
                    <List>
                      {report.files.map((file) => {
                        return (
                          <ListItem key={file.filename}>
                            <DownloadFile file={file} />
                          </ListItem>
                        );
                        // switch (true) {
                        //   case /video/.test(file.mimetype):
                        //     return <VideoPlayer path={file.path} filename={file.filename} />;
                        //   default:
                        //     return <Image path={file.path} filename={file.filename} />;
                        //     break;
                        // }
                      })}
                    </List>
                  </Stack>
                ) : null}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Useragent</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ overflowY: "auto" }}>
                    <pre>{JSON.stringify(report.useragent, null, 2)}</pre>
                  </AccordionDetails>
                </Accordion>
                <Stack>
                  <Typography variant="h6">Saite:</Typography>
                  <Typography>
                    <Link href={report.link}>{report.link}</Link>
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h6">Pieteicēja komentārs:</Typography>
                  <Typography>{report.reporterComment}</Typography>
                </Stack>
                <TextField
                  id="moderatorComment"
                  label="Komentārs"
                  multiline
                  maxRows={10}
                  minRows={2}
                  fullWidth
                  value={values.moderatorComment}
                  onChange={handleChange}
                  error={Boolean(errors.moderatorComment) && submitCount > 0}
                  helperText={submitCount > 0 ? errors.moderatorComment : null}
                />
                <FormControlLabel
                  checked={values.realized}
                  control={<Switch />}
                  label="Informācija realizējās"
                  onChange={(e, checked) => setFieldValue("realized", checked)}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                loading={isDeleting}
                color="error"
                onClick={onDelete}
                variant="outlined"
              >
                Dzēst
              </LoadingButton>
              <Button onClick={handleClose} variant="outlined">
                Atcelt
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={() => handleSubmit()}
                variant="contained"
              >
                Apstiprināt
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default ModerateModal;
