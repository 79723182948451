// utils
import { createSlice } from "@reduxjs/toolkit";

export interface ISettings {
  fluid: boolean;
}

const initialState: ISettings = {
  fluid: false,
};

const settingsSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: () => initialState,
    setFluid: (state, { payload }: { payload: any }) => {
      state.fluid = payload;
    },
  },
});

export const { clearAuth, setFluid } = settingsSlice.actions;

export default settingsSlice.reducer;
