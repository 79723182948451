import { useCallback, useEffect, useState } from "react";

import SOURCES, { LIST as SOURCES_LIST } from "../constants/source";
import TYPES, { LIST as TYPES_LIST } from "../constants/type";
import STATUSES, { LIST as STATUSES_LIST } from "../constants/status";

import axios from "axios";

import { Grid, Link, Paper, Stack, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { Pagination } from "@mui/lab";
import { IPagination } from "../types/common";
import { IReportResponse } from "../types/report";
import moment from "moment";
import { useAppDispatch } from "../hooks/redux";
import { openModal } from "../features/modals";
import MODALS from "../constants/modals";

const Reports: React.FunctionComponent<{}> = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(true);
  const [request, setRequest] = useState({
    page: 1,
  });
  const [data, setData] = useState<IPagination<IReportResponse>>();

  const loadData = useCallback(() => {
    setLoading(true);
    axios
      .get<IPagination<IReportResponse>>("/report", { params: request })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [request]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns: GridColDef[] = [
    {
      field: "seq",
      headerName: "id",
      width: 30,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Statuss",
      width: 82,
      renderCell: (params) => {
        const status = STATUSES_LIST.find((s) => s.type === (params.value as STATUSES));

        if (status) {
          return (
            <Tooltip title={status.name}>
              <span className="table-cell-trucate">{status.icon}</span>
            </Tooltip>
          );
        }
        return "";
      },
      align: "center",
    },
    {
      field: "created",
      headerName: "Izveidots",
      width: 150,
      valueFormatter: (params) => {
        return moment
          .utc(params.value as Date)
          .local()
          .format("L LT");
      },
    },
    {
      field: "source",
      headerName: "Avots",
      width: 80,
      renderCell: (params) => {
        const source = SOURCES_LIST.find((s) => s.type === (params.value as SOURCES));

        if (source) {
          return source.icon;
        }
        return "";
      },
      sortable: false,
    },
    {
      field: "type",
      headerName: "Tips",
      width: 80,
      renderCell: (params) => {
        const type = TYPES_LIST.find((t) => t.type === (params.value as TYPES));

        if (type) {
          return type.icon;
        }
        return "";
      },
      sortable: false,
    },
    { field: "reporterComment", headerName: "Komentārs", minWidth: 200, flex: 1, sortable: false },
    {
      field: "link",
      headerName: "Saite",
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <Link href={params.value} target="_blank">
            {params.value}
          </Link>
        );
      },
    },
  ];

  return (
    <Paper sx={{ padding: 2 }}>
      <Stack spacing={2}>
        <DataGrid
          getRowId={(row) => row._id}
          rows={data && data.docs ? data.docs : []}
          columns={columns}
          hideFooterPagination
          loading={isLoading}
          autoHeight
          // onSortModelChange={onSortModelChange}
          disableSelectionOnClick
          disableColumnMenu
          // sortingMode="server"
          hideFooter
          onRowClick={(params) => {
            dispatch(
              openModal({
                type: MODALS.View,
                report: params.row as IReportResponse,
              })
            );
          }}
        />

        <Grid container justifyContent="center">
          <Grid item>
            <Pagination
              color="primary"
              variant="outlined"
              count={data?.totalPages}
              page={request.page}
              onChange={(e, page) => {
                setRequest((e) => ({ ...e, page }));
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};

export default Reports;
