import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation, faArrowDown } from "@fortawesome/free-solid-svg-icons";

export enum PRIORITIES {
  Queue = "queue",
  Urgent = "urgent",
}

export interface IPriorityListItem {
  type: PRIORITIES;
  name: string;
  icon: JSX.Element;
}

export const QUEUE_LIST_ITEM: IPriorityListItem = {
  type: PRIORITIES.Queue,
  name: "Rindas kārtībā",
  icon: <FontAwesomeIcon icon={faArrowDown} />,
};

export const URGENT_LIST_ITEM: IPriorityListItem = {
  type: PRIORITIES.Urgent,
  name: "Steidzami",
  icon: <FontAwesomeIcon icon={faTriangleExclamation} />,
};

export const LIST: IPriorityListItem[] = [QUEUE_LIST_ITEM, URGENT_LIST_ITEM];

export default PRIORITIES;
