import { IApproveModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { Dialog, DialogActions, DialogTitle, Button } from "@mui/material";

const ApproveModal: React.FunctionComponent<IApproveModalProps> = ({ id, title, onApprove }) => {
  const dispatch = useAppDispatch();

  const handleApprove = () => {
    onApprove();
    handleClose();
  };

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Atcelt</Button>
        <Button onClick={handleApprove}>Apstiprināt</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveModal;
